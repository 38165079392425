import { ReactElement } from 'react';

import { TextLink } from '@components/Typography/TextLink/TextLink';
import { AlgoliaCategory } from '@interfaces/Category';
import { TrimText as textTrim } from '@lib/formatText';

export function SearchResultCardCategory({
  hit,
}: {
  hit: AlgoliaCategory;
}): ReactElement {
  return (
    <div className="py-=2.5 flex flex-col gap-1 border-0 border-b border-solid border-grey-light px-0">
      <TextLink href={hit.slug}>
        <span className="text-lg">{hit.name}</span>
      </TextLink>
      <span>{hit.shortDescription && textTrim(hit.shortDescription, 248)}</span>
    </div>
  );
}
