import Link from 'next/link';
import { ReactElement } from 'react';

import { AlgoliaItem } from '@interfaces/Search';

export const SearchResultsLinkList = ({
  items,
  maxItems = 4,
  noResultsMessage = 'No results found',
}: {
  items: Array<AlgoliaItem>;
  maxItems?: number;
  noResultsMessage: string;
}): ReactElement => (
  <>
    {items.length === 0 ? (
      <p>{noResultsMessage}</p>
    ) : (
      <div className="flex flex-col">
        {items.slice(0, maxItems).map((item) => {
          /* rewards page needs to be fully reload because Loyalty Lion doesn't fire on shallow routing */
          if (item.slug === '/rewards') {
            return (
              <a
                key={item.objectID}
                href={item.slug}
                className="cursor-pointer p-1.5 text-base font-semibold text-grey-dark underline decoration-grey-mid decoration-1 underline-offset-[0.1em] duration-500 hover:text-black hover:decoration-orange hover:decoration-3 hover:duration-200 dark:text-white"
              >
                {item.title}
              </a>
            );
          }

          return (
            <Link
              key={item.objectID}
              href={item.slug ? item.slug : '#'}
              passHref
              className="cursor-pointer p-1.5 text-base font-semibold text-grey-dark underline decoration-grey-mid decoration-1 underline-offset-[0.1em] duration-500 hover:text-black hover:decoration-orange hover:decoration-3 hover:duration-200 dark:text-white"
            >
              {item.title}
            </Link>
          );
        })}
      </div>
    )}
  </>
);
