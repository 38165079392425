import Link from 'next/link';
import { ReactElement } from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { MdOutlineCheckCircle } from 'react-icons/md';
import { Configure } from 'react-instantsearch-dom';
import { twJoin } from 'tailwind-merge';

import { AddToFavourites, Button, QuickAddButton } from '@components/Button';
import { ButtonStyle } from '@components/Button/Button';
import { CustomImage } from '@components/Image';
import { StarRating } from '@components/Product/StarRating';
import { TooltipWrapper } from '@components/Tooltip/Tooltip';
import { AlgoliaProduct } from '@interfaces/Product';
import { Stores, updateIndexedDBData } from '@lib/localData';
import { logBreadcrumb } from '@lib/utils';

export function SearchResultCardProduct({
  hit,
  insights,
  configureUsed = false, // default to false because less chance of killing app
}: {
  hit: AlgoliaProduct;
  insights?: Function;
  configureUsed?: boolean;
}): ReactElement {
  const price =
    hit.pricing.priceRange.min.value === hit.pricing.priceRange.max.value
      ? `$${hit.pricing.priceRange.min.value.toFixed(2)}`
      : `$${hit.pricing.priceRange.min.value.toFixed(
          2
        )} - $${hit.pricing.priceRange.max.value.toFixed(2)}`;

  const image = hit.images ? hit.images.filter((i) => i.defaultImage)[0] : null;

  let url = `${hit.slug}`;
  // store this in local storage so we can use it in the product page
  let queryId;
  // redirect to product page with hidden queryID
  if (insights && hit.__queryID) {
    queryId = hit.__queryID;
    url += `?queryID=${hit.__queryID}`;
  } else if (hit.__autocomplete_queryID) {
    // if this product was generated from the autocomplete, track it as a search result
    queryId = hit.__autocomplete_queryID;
    url += `?queryID=${hit.__autocomplete_queryID}`;
  }

  const handleProductClicked = () => {
    // send analytics event to Algolia
    if (insights && hit.__queryID) {
      insights('clickedObjectIDsAfterSearch', {
        eventName: 'Product Clicked After Search',
      });
    }
  };

  // detect middle mouse click
  // when middle mouse click, a in new tab is open, so we cannot get the queryId from the url
  // so we need to store it in the local storage
  const mouseDownHandler = async (event) => {
    try {
      if (event.button === 1) {
        await updateIndexedDBData(Stores.keyvaluepairs, 'queryId', queryId);
        handleProductClicked();
      }
    } catch (err) {
      logBreadcrumb({
        category: 'IndexedDB',
        message: `Tried to set queryid`,
        level: 'error',
      });
    }
  };

  // detect right click and open new tab
  // prepare the data to be sent to the new tab
  const contextmenuHandler = async () => {
    // Put the product hit into storage
    try {
      await updateIndexedDBData(Stores.keyvaluepairs, 'hit', hit);
    } catch (err) {
      logBreadcrumb({
        category: 'IndexedDB',
        message: `Tried to set hit on search result card product`,
        level: 'error',
      });
    }
  };

  return (
    <div
      className={twJoin([
        'card gae-search-pc',
        'grid h-full max-w-[450px] grid-cols-[100px_auto] content-between gap-0 tiny:grid-cols-[150px_auto]',
      ])}
    >
      {/* Got to check if this search result is a child of an Algolia search component. If not, configure won't be used. */}
      {configureUsed && <Configure clickAnalytics />}

      {image && (
        <Link
          prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
          href={url}
          as={hit.slug}
          onContextMenu={contextmenuHandler}
          onMouseDown={mouseDownHandler}
          onClick={handleProductClicked}
        >
          <CustomImage
            image={image}
            width={150}
            height={150}
            className="w-[100px] tiny:w-full"
          />
        </Link>
      )}

      <div className="relative flex min-h-[100px] flex-col bg-grey-light px-2 text-grey-dark dark:bg-grey-dark dark:text-white tiny:min-h-[150px]">
        <div className="flex h-8 justify-between">
          <span className="gae-search-title mt-1.5 font-secondary uppercase">
            <Link
              prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
              href={url}
              as={hit.slug}
              onContextMenu={contextmenuHandler}
              onMouseDown={mouseDownHandler}
              onClick={handleProductClicked}
              className="line-clamp-1 cursor-pointer font-secondary text-2xl font-semibold text-grey-dark underline decoration-grey-mid decoration-1 underline-offset-[0.1em] duration-500 hover:text-black hover:decoration-orange hover:decoration-3 hover:duration-200 dark:text-white dark:hover:text-white"
            >
              {hit.name}
            </Link>
          </span>
          <AddToFavourites sku={hit.sku} productId={hit.entityId} />
        </div>

        {!!hit.averageRating && (
          <div className="mb-1 flex items-center gap-1 text-xs">
            <StarRating rating={hit.averageRating} />
            {hit.averageRating} from {hit.totalReviews} reviews
          </div>
        )}
        <div className="grow">
          <div className="line-clamp-2 hidden text-ellipsis text-xs tiny:[display:-webkit-box]">
            {hit.shortDescription}
          </div>
        </div>

        <div className="grid grid-cols-2 items-center font-secondary text-2xl font-bold">
          <span>{price}</span>

          <div className="flex gap-4 justify-self-end">
            <Button
              href={hit.slug}
              buttonStyle="tertiary"
              className="hover:scale-120"
              icon={<IoInformationCircleOutline size="1.5rem" />}
            />
            <QuickAddButton product={hit} className="hover:scale-120" />
          </div>

          {!!hit.pricePerServe ? (
            <span className="font-secondary text-base font-light uppercase text-black dark:text-white">
              <MdOutlineCheckCircle className="fill-green dark:fill-green-light" />{' '}
              {hit.pricePerServe < 1
                ? `${Math.round(hit.pricePerServe * 100).toFixed(0)}c`
                : `$${hit.pricePerServe.toFixed(2)}`}{' '}
              per serve
            </span>
          ) : (
            <span></span>
          )}

          {hit.pricing.bulkPricing.length > 0 ||
          hit.variants.some((v) => v.pricing.bulkPricing.length > 0) ? (
            <span className="text-right font-secondary text-base font-light uppercase text-black dark:text-white">
              <MdOutlineCheckCircle className="fill-green dark:fill-green-light" />
              Multibuy Discount
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
}
