import useSWRInfinite from 'swr/infinite';

import { BulkAPIErrorResponse } from '@interfaces/BulkAPI';
import { SEARCH_INDEXES } from '@lib/constants';

export interface IndexResult {
  query: string;
  index: (typeof SEARCH_INDEXES)[keyof typeof SEARCH_INDEXES];
  hits: Array<any>;
  nbHits: number;
  hitsPerPage: number;
  page: number;
  facets?: any;
}

interface SearchResult {
  results: Array<IndexResult>;
}
interface IndexRequest {
  indexName: (typeof SEARCH_INDEXES)[keyof typeof SEARCH_INDEXES];
  count: boolean;
  params: {
    query: string;
    page?: number;
    limit?: number;
    sortBy?: string;
    sortDirection?: string;
    filters?: Array<any>;
  };
}

interface Props {
  index: (typeof SEARCH_INDEXES)[keyof typeof SEARCH_INDEXES] | '*';
  query: string;
  pageSize?: number;
  baseUrl?: string;
}

interface UseSearch {
  isLoadingMore: boolean | undefined;
  isEmpty: boolean;
  isReachingEnd: boolean | undefined;
  data: Array<IndexResult> | undefined;
  counts: Array<{ hits: number; index: string }> | undefined;
  error: any;
  size: number;
  setSize: (size: number | ((size: number) => number)) => void;
}

const fetcher = async (url) => {
  const page = new URL(url).searchParams.get('pageIndex');
  const index = new URL(url).searchParams.get('index');
  const query = new URL(url).searchParams.get('query') ?? '';
  const pageSize = parseInt(new URL(url).searchParams.get('pageSize') ?? '15');

  // Don't do all product indices, either the selected or featured
  const keys = Object.keys(SEARCH_INDEXES).filter(
    (key) => !key.includes('PRODUCTS') || index === SEARCH_INDEXES[key]
  );
  if (!keys.find((key) => key.includes('PRODUCTS'))) {
    keys.push('PRODUCTS');
  }

  // Request all indices, but counts only for the non specified
  const body = {
    requests: keys.map((searchIndex) => {
      const request: IndexRequest = {
        indexName: SEARCH_INDEXES[searchIndex],
        count: index === '*' ? false : index !== SEARCH_INDEXES[searchIndex],
        params: {
          query,
          page: parseInt(page as string) + 1,
          limit: pageSize,
        },
      };
      return request;
    }),
  };
  const res = await fetch(url.slice(0, url.indexOf('?')), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(body),
  });

  // If not ok, then get the error
  if (res.status !== 200) {
    const {
      error: { message, code },
    } = (await res.json()) as BulkAPIErrorResponse;
    const error = new Error('Unable to fetch products');
    error.message = message.join('. ');
    throw error;
  }

  // Everything as expected
  const { results } = await (res.json() as Promise<SearchResult>);
  return results;
};

// Hook
export function useSearch({
  index,
  query,
  pageSize,
  baseUrl = process.env.NEXT_PUBLIC_SEARCH_URL,
}: Props): UseSearch {
  const PAGE_SIZE = pageSize ?? 15;

  // A function to get the SWR key of each page,
  // its return value will be accepted by `fetcher`.
  // If `null` is returned, the request of that page won't start.
  const getKey = (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null; // reached the end
    const apiUrl = new URL('/search/*/', baseUrl);
    apiUrl.searchParams.set('pageIndex', pageIndex);
    apiUrl.searchParams.set('query', query);
    apiUrl.searchParams.set('index', index);
    apiUrl.searchParams.set('pageSize', PAGE_SIZE.toString());

    return apiUrl.origin + apiUrl.pathname + apiUrl.search;
  };

  const { data, error, isLoading, isValidating, size, setSize } =
    useSWRInfinite<IndexResult[]>(getKey, fetcher, {
      keepPreviousData: true,
      revalidateOnFocus: false,
    });

  const latestData =
    data && data[data.length - 1].filter((d) => d.index === index)[0];

  const isLoadingMore =
    isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd =
    isEmpty ||
    (latestData && index !== '*' && latestData.hits.length < PAGE_SIZE);

  const indexFormattedData = data?.reduce((acc, curr) => {
    curr.forEach((d) => {
      const existingResult = acc.find((a) => a.index === d.index);
      if (existingResult) {
        const hits = d.hits.filter(
          (hit) => !existingResult.hits.find((h) => h.objectID === hit.objectID)
        );
        existingResult.hits.push(...hits);
      } else {
        acc.push(d);
      }
    });
    return acc;
  }, [] as Array<IndexResult>);

  return {
    isLoadingMore,
    isEmpty,
    isReachingEnd,
    data: indexFormattedData,
    counts: data
      ? data[data?.length - 1].map((d) => ({
          hits: d.nbHits,
          index: d.index,
        }))
      : [],
    error,
    size,
    setSize,
  };
}
