import Link from 'next/link';
import { ReactElement } from 'react';

import { CustomImage } from '@components/Image';
import { AlgoliaBlog } from '@interfaces/Blog';

export function SearchResultCardCompactBlog({
  hit,
}: {
  hit: AlgoliaBlog;
}): ReactElement {
  return (
    <div
      className="card gae-search-pc grid h-full max-w-[450px] grid-cols-[auto_1.3fr] 
    grid-rows-[auto_fit-content(20%)] gap-y-0 dark:bg-grey-darkest dark:text-white"
    >
      <div className="m-auto w-[110px]">
        <Link
          prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
          href={hit.slug ? hit.slug : '/'}
        >
          <CustomImage
            image={hit.featuredImage}
            width={150}
            height={150}
            className="gap-0"
          />
        </Link>
      </div>

      <div className="flex flex-col justify-start bg-grey-light px-2 py-1 dark:bg-grey-dark">
        <p className="my-1 ml-auto text-xs text-orange">{hit.category}</p>
        <span className="gae-search-title mb-2.5 font-secondary text-base font-bold uppercase">
          <Link
            prefetch={process.env.NEXT_PUBLIC_PREFETCH === 'true'}
            href={hit.slug ? hit.slug : '/'}
            title={hit.title}
            className="cursor-pointer font-secondary text-xl font-semibold text-grey-dark underline decoration-grey-mid decoration-1 underline-offset-[0.1em] duration-500 hover:text-black hover:decoration-orange hover:decoration-3 hover:duration-200 dark:text-white"
          >
            {hit.title}
          </Link>
        </span>

        {!!hit.estimatedReadingTime && (
          <p className="mb-1 mt-auto text-xs">
            Reading time:{' '}
            {hit.estimatedReadingTime === 1
              ? '1 minute'
              : `${hit.estimatedReadingTime} minutes`}
          </p>
        )}
      </div>

      <div className="relative col-start-[span_2] col-end-auto flex flex-col items-center justify-end">
        <Link
          href={hit.slug ? hit.slug : '/'}
          className="hover:bg-primary block w-full bg-black p-1 text-center text-white no-underline hover:text-white"
        >
          View post
        </Link>
      </div>
    </div>
  );
}
