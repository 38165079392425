import { ReactElement } from 'react';

import { TextLink } from '@components/Typography/TextLink/TextLink';
import { RewardsLink } from '@components/Typography/TextLink/TextLink';
import { AlgoliaPage } from '@interfaces/InformationPage';
import { TrimText as textTrim } from '@lib/formatText';

export function SearchResultCardPage({
  hit,
}: {
  hit: AlgoliaPage;
}): ReactElement {
  return (
    <div className="flex flex-col gap-1 border-0 border-b border-solid border-grey-light px-0 py-2.5">
      {/* rewards page needs to be fully reload because Loyalty Lion doesn't fire on shallow routing */}
      {hit.slug === '/rewards' ? (
        <RewardsLink>
          <span className="text-lg">{hit.title}</span>
        </RewardsLink>
      ) : (
        <TextLink href={hit.slug}>
          <span className="text-lg">{hit.title}</span>
        </TextLink>
      )}

      <span>{textTrim(hit.description, 248)}</span>
    </div>
  );
}
